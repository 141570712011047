<template>
  <div class="static-page">

    <b-container>
      <section class="section text-center">
        <h1 class="h1">Termini e condizioni</h1>
        <p>
          <b>
            SI PREGA DI LEGGERE ATTENTAMENTE QUESTI TERMINI E CONDIZIONI. ESSI CONTENGONO IMPORTANTI INFORMAZIONI SUL FUNZIONAMENTO DEL SERVIZIO
            {{ uppercaseBrand }} {{uppercasePayoff}} E SULLE LIMITAZIONI DI RESPONSABILITÀ
          </b>
        </p>
      </section>

      <section class="section">
        <h2 class="h2 text-center">DEFINIZIONI</h2>

        <p>
          L’espressione <b>SITO WEB {{ uppercaseBrand }} {{uppercasePayoff}}</b> indica i software accessibili all’indirizzo <a :href="host">{{host}}</a> che permettono la visualizzazione e la prenotazione delle disponibilità di passaggi aerei, servizi turistici e pacchetti viaggio.
        </p>
        <p>
          L’espressione <b>PACCHETTO VIAGGIO</b> indica una vendita che comprende almeno due tipi diversi di servizi turistici per uno stesso viaggio o vacanza, prenotati nel quadro di un unico contratto con Salabam srl.
        </p>

        <p>
          Il termine <b>DISPONIBILITA’</b> indica le proposte di pacchetti viaggio visualizzabili e prenotabili sul Sito Web
          {{ site.brand }} {{ site.payoff }}. Le disponibilità sono proposte dal Sito Web {{ site.brand }} {{ site.payoff }} in tempo reale, nel momento di utilizzo del Sito Web {{ site.brand }} {{ site.payoff }} stesso da parte dell’Utente. Le Disponibilità vengono rese disponibili direttamente dai Tour Operator e sono rese disponibili da {{ site.brand }} {{ site.payoff }} ai propri Utenti. Le disponibilità sono quindi soggette ad una contrattualizzazione diretta tra {{ site.brand }} {{ site.payoff }} (Salabam srl) e l'Utente.
        </p>

        <p>
          I termini <b>NOI</b>, <b>SOCIETÀ</b> e <b>{{ uppercaseBrand }} {{ uppercasePayoff }}</b>  si riferiscono ad un servizio online fornito da Salabam srl, una società a responsabilità limitata Italiana con sede in Via del Porto 50, 47841 Cattolica, RN, Italia.
        </p>
        <p>
          Con l’espressione <b>UTENTE</b> si intende la persona fisica che visita il Sito web {{ site.brand }} {{ site.payoff }} e usufruisce dei suoi servizi.
        </p>


        <p>
          <b>PORTALE PARTNER</b> indica un sito web convenzionato con {{ site.brand }} {{ site.payoff }} e Salabam srl il quale tramite un meccanismo tecnologico di autenticazione sicura, chiamato Single Sign-On (SSO), attribuisce al singolo utente un credito virtuale riconosciuto da {{ site.brand }} {{ site.payoff }} e spendibile sul sito {{ site.brand }} {{ site.payoff }}.
        </p>

        <p>
          Il termine <b>PRENOTAZIONE</b> indica l’operazione con la quale l’Utente prenota un Pacchetto Viaggio tramite i servizi online forniti dal Sito Web {{ site.brand }} {{ site.payoff }} utilizzando una qualsiasi forma di pagamento proposta da {{ site.brand }} {{ site.payoff }} incluso il pagamento tramite il Credito personale a lui messo a disposizione, sul Portale Partner stesso, e riconosciuto ed accettato come strumento di pagamento da {{ site.brand }} {{ site.payoff }}.
        </p>


        <p>
          L’espressione <b>NUMERO PRENOTAZIONE</b> indica il riferimento univoco della prenotazione di un Pacchetto Viaggio ed è incluso nel documento inviato via email all’Utente a seguito della sua Prenotazione e recante anche il riepilogo dettagliato dei servizi prenotati.
        </p>


        <p>
          Con l’espressione <b>TOUR OPERATOR</b> si intende il fornitore dei Pacchetti Viaggio.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">INDICAZIONI GENERALI</h2>

        <p>
          <b>
            L’utilizzo di questo sito internet è vincolato all’accettazione senza riserve dei termini, condizioni ed avvertenze ivi contenuti, in vigore al momento della prenotazione. Qualsiasi utilizzo da parte dell’utente o da parte nostra delle informazioni relative al conto personale ed alle prenotazioni effettuate tramite il nostro sito è soggetto ai seguenti termini, condizioni e notifiche. L’utilizzo del sito costituisce la sottoscrizione a tutti i summenzionati termini, condizioni ed avvertenze.
          </b>
        </p>

        <p>
          L’utilizzo del sito è altresì soggetto ai termini e alle condizioni applicate da Alpitour S.p.A. e reperibili al seguente indirizzo
          <a :href="site.host + 'assets/files/alpitour/condizioni_uso_vacanzeByVadoBay.pdf'" target="_blank">{{ site.host }}assets/files/alpitour/condizioni_uso_vacanzeByVadoBay.pdf</a>
        </p>

        <p>
          <b>
            Se l’utente non accetta tali termini e condizioni non è autorizzato ad utilizzare il sito web. Per ogni controversia sarà esclusivamente competente il Foro di Rimini.
          </b>
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">FUNZIONAMENTO E SERVIZI {{ uppercaseBrand }} {{ uppercasePayoff }}</h2>

        <p>
          Il Sito Web {{ site.brand }} {{ site.payoff }} permette all’Utente la prenotazione di Pacchetti Viaggio. Il Sito Web {{ site.brand }} {{ site.payoff }} propone all’Utente disponibilità reali di Paccheti Viaggio, immediatamente confermabili ottenute dinamicamente dai Tour Operator.
        </p>

        <p>
          L’utente riconosce ed accetta il fatto che {{ site.brand }} {{ site.payoff }} (Salabam srl) eroghi un servizio a fronte di adeguata remunerazione del servizio stesso. Il servizio remunerato è:
        </p>

        <ol>
          <li>
            un servizio di prenotazione di Pacchetti Viaggio agevolato da un sistema che permette all’Utente di effettuare in totale libertà ricerche su un elevato numero di destinazioni turistiche, su date e numero di partecipanti
          </li>
          <li>
            un servizio che permette a terzi (aziende o organizzazioni) di gestire i loro piani di Welfare Aziendale, o parte di questi, operazioni di promozione commerciale, piani di regalistica, di incentivazione e loyalty.
          </li>
          <li>
            un servizio di assistenza clienti gratuito, attivabile tramite telefono, email, webchat, instant messaging.
          </li>
        </ol>

        <p>
          A seguito della prenotazione l'Utente riceverà all'indirizzo email comunicato al momento della prenotazione stessa la conferma di prenotazione con il relativo Numero di prenotazione e, non appena questa sarà resa disponibile dal Tour Operator, una documentazione di viaggio completa.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">LIMITAZIONE DI RESPONSABILITA’</h2>

        <p>
          Le informazioni, i software, i prodotti e servizi pubblicati su questo sito possono presentare inaccuratezze o errori tipografici. Le informazioni vengono comunicate a {{ site.brand }} {{ site.payoff }} dai Tour Operator in tempo reale e in via telematica. Per questo motivo le informazioni di disponibilità e le descrizioni dei Pacchetti Viaggio proposti all’Utente vengono costantemente aggiornate e modificate. {{ site.brand }} {{ site.payoff }} o i rispettivi fornitori possono apportare miglioramenti o modifiche al presente sito in qualsiasi momento. Esaurimento o modifiche delle disponibilità proposte possono verificarsi in qualsiasi momento senza preavviso e senza alcuna responsabilità per {{ site.brand }} {{ site.payoff }}.
        </p>

        <p>
          {{ site.brand }} {{ site.payoff }} e Salabam srl non sono responsabili, nè emetteranno alcun rimborso, in caso di ritardo, cancellazione, overbooking, sciopero, forza maggiore o altri eventi che non dipendano dal loro controllo diretto e non sono responsabili per ogni altra spesa aggiuntiva, omissione, cambio di itinerario o azioni causati da governi o autorità.
        </p>
        <p>
          A prescindere dalle informazioni fornite via web o tramite messaggi email all’Utente da {{ site.brand }} {{ site.payoff }} a seguito della prenotazione di un Pacchetto Viaggio è fortemente consigliato che l'Utente visiti l’area a lui riservata sulla mobile app del Tour Operator Alpitour S.p.A, MyAlpitour World, al fine di ottenere le informazioni complete e necessarie alla fruizione del Pacchetto Viaggio quali modalità e condizioni di check-in, condizioni di trasporto, franchigie bagagli, ecc.. Salabam Srl non può essere ritenuta responsabile di qualsiasi danno o pregiudizio causato all’Utente dal non rispetto da parte dell’Utente stesso delle condizioni d'uso e di viaggio stabilite dal Tour Operator      </p>


        <p>
          In nessun caso {{ site.brand }} {{ site.payoff }}, Salabam srl e i rispettivi fornitori, verranno ritenuti responsabili per qualsiasi sanzione, ne’ per qualsiasi danno diretto, incidentale, specifico o emergente causato da, o in ogni modo connesso a, l’utilizzo di questo sito.
        </p>

        <p>
          D’altronde {{ site.brand }} {{ site.payoff }} e Salabam srl non escludono o limitano in alcun modo la propria responsabilità su questioni che non possano essere oggetto di esclusione di responsabilità ai sensi della legge vigente.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">LINK A SITI DI TERZI</h2>

        <p>
          Questo sito può contenere link ad altri siti, gestiti da società diverse da Salabam srl. Tali link sono forniti solo per riferimento. Salabam non controlla tali siti e non è responsabile del loro contenuto, né dell’utilizzo che ne fa l’Utente. Il fatto che {{ site.brand }} {{ site.payoff }} includa dei link ai siti summenzionati non implica in alcun modo l’approvazione del materiale presente sui siti linkati né sta ad indicare alcuna associazione con gli operatori di detti siti.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">SOFTWARE DISPONIBILI SUL SITO</h2>

        <p>
          Qualsiasi software o servizio utilizzabile sul Sito Web {{ site.brand }} {{ site.payoff }} è coperto dal diritto di autore di Salabam srl.
        </p>

        <p>
          Senza limitazione a quanto appena dichiarato, la copia o la riproduzione del software su qualsiasi altro server o supporto per ulteriore riproduzione o diffusione è espressamente proibita.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">ALTRI TERMINI DI UTILIZZO PER L’APPLICAZIONE {{ uppercaseBrand }} {{ uppercasePayoff }} PER DISPOSITIVI PORTATILI</h2>

        <p>
          Non esiste una corrispondenza perfetta tra le funzioni del sito fruite da desktop e quelle fruite da mobile, tablet o altri dispositivi.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">RISERVA DEI DIRITTI</h2>

        <p>
          L’utente è consapevole che tutti i diritti di proprietà intellettuale, la proprietà stessa e tutti gli interessi nell’Applicazione e nei Contenuti appartengono a Salabam srl e ai suoi fornitori. Tali diritti sono tutelati da leggi e trattati internazionali. Tutti questi diritti sono riservati.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">INFORMAZIONI PER L’UTENTE E PER L’UTILIZZO DELL’APPLICAZIONE</h2>

        <p>
          L’elaborazione delle informazioni sull’Utente è trattata in modo conforme alla nostra Informativa sulla privacy. Utilizzando questa Applicazione, l’Utente acconsente a tale elaborazione. Si consiglia pertanto la lettura integrale della nostra <a :href="site.urlPrivacy" target="_blank">Informativa sulla privacy</a>.
        </p>

      </section>

      <section class="section">

        <h2 class="h2 text-center">PAGAMENTO ONLINE</h2>

        <p>Il pagamento del corrispettivo delle singole prenotazioni può avvenire tramite:</p>

        <div v-if="isVadobay">

          <ol>
            <li>addebito diretto ed istantaneo sul conto welfare dell'utente</li>
            <!--<li>buono sconto {{site.brand}} {{site.payoff}}</li>-->
          </ol>

        </div>

        <div v-else>

          <ol>
            <li>carte di credito: {{site.brand}} {{site.payoff}} accetta tutte le principali carte di credito, American Express, Mastercard, Visa</li>
            <li v-if="etcEnabled">Ticket Compliments Edenred</li>
            <li v-if="promoshoppingEnabled">Promoshopping</li>
            <li>buono sconto {{site.brand}} {{site.payoff}}</li>
            <li v-if="scalapayEnabled">Scalapay</li>
          </ol>

          <br/>

          <p>Nella pagina di check-out l'utente trova tutte le possibili opzioni di pagamento.</p>

        </div>

        <p v-if="scalapayEnabled">
          L'utente che effettua la sua prenotazione e paga con Scalapay riceve il suo ordine subito e paga in 3 rate. L'utente prende atto del fatto che le rate verranno cedute a Incremento SPV S.r.l., a soggetti correlati e ai loro cessionari, e che autorizza tale cessione.
        </p>

      </section>

      <section class="section">
        <h2 class="h2 text-center">VARIAZIONI E CANCELLAZIONI</h2>

        <p>
          I Pacchetti viaggio proposti su {{ site.brand }} {{ site.payoff }} sono di tipo NON-RIMBORSABILE.
        </p>
        <p>
          I Pacchetti viaggio disponibili su {{ site.brand }} {{ site.payoff }} sono soggetti a modifiche delle condizioni di trasporto, ritardi, cancellazioni, ri-programmazioni decisi dai Tou operator. Nei casi in cui la decisione del Tour operator implichi un rimborso, lo stesso rimborso, nel momento in cui viene liquidato dal Tour Operator, genera un Buono sconto utilizzabile nell'arco di 12 mesi per una nuova prenotazione sul sito {{ site.payoff }} {{site.brand}}.
        </p>

      </section>


      <section class="section">
        <h2 class="h2 text-center">DENUNCE DI VIOLAZIONE DEL COPYRIGHT</h2>

        <p>
          Qualora l’Utente ritenga in buona fede che il materiale ospitato da {{ site.brand }} {{ site.payoff }} violi il proprio copyright, può inviarci, anche tramite un suo rappresentante, una comunicazione scritta che includa le informazioni richieste qui di seguito. {{ site.brand }} {{ site.payoff }} non prenderà in considerazione le richieste dell’Utente non compilate correttamente o incomplete. Nel caso in cui la comunicazione contenga false dichiarazioni circa il fatto che un determinato contenuto o attività costituisce una violazione, l’Utente potrebbe rispondere dei danni.
        </p>

        <ol>
          <li>
            Elementi che consentano di identificare chiaramente l’opera protetta dal copyright che sarebbe stato violato
          </li>
          <li>
            Elementi che consentano di identificare chiaramente il materiale che costituirebbe violazione dell’opera protetta da copyright, in modo da consentire a {{ site.brand }} {{ site.payoff }} di individuare tale materiale sul sito Web (ad esempio, un collegamento al suddetto materiale)
          </li>
          <li>
            Informazioni utili per contattare l’Utente affinché {{ site.brand }} {{ site.payoff }} possa rispondere alla richiesta, inclusi, possibilmente, un indirizzo e-mail e un numero di telefono
          </li>
          <li>
            Una dichiarazione con cui l’Utente afferma di “aver ritenuto in buona fede che la pubblicazione del materiale in violazione del copyright non sia autorizzata dal titolare del copyright, da un suo rappresentante o dalla legge
          </li>
          <li>
            Una dichiarazione secondo cui “le informazioni riportate nella notifica sono precise e si dichiara sotto giuramento che la parte che presenta denuncia è autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato”
          </li>
          <li>
            La comunicazione deve essere firmata dalla persona autorizzata ad agire in nome del titolare del diritto esclusivo che si presume violato
          </li>
        </ol>

        <p>
          L’Utente può inviare la propria comunicazione per e-mail all’indirizzo <a :href="'mailto:' + site.emailSupport">{{site.emailSupport}}</a>
        </p>

      </section>

      <section class="section">

        <p>
          Versione rivista e in vigore dal 12 aprile 2023
        </p>

        <p>
          ©2023 Salabam SRL, Tutti i diritti riservati
        </p>

      </section>

    </b-container>
  </div>
</template>


<script>
import Vue from "vue";

export default {
  name: 'terms',
  metaInfo: {
    title: 'termini e condizioni',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Scopri le condizioni del nostro servizio di prenotazione viaggi' },
    ],
  },
  components: {
  },
  methods: {
  },
  data(){
    return {
      site: Vue.prototype.$config.site,
      uppercaseBrand: Vue.prototype.$config.site.brand.toUpperCase(),
      uppercasePayoff: Vue.prototype.$config.site.payoff.toUpperCase(),
      host: process.env.VUE_APP_SITE_HOST,

      isVadobay: true, // compatibilità con vadobay/vivamod, serve solo a pilotare l'elenco dei sistemi di pagamento su questa pag

      //etcEnabled: this.$config.guiSettings.integrations.etc.enabled,
      //scalapayEnabled: (this.$config.guiSettings.integrations.scalapay.enabled)? true : false,
      //promoshoppingEnabled: (this.$config.guiSettings.integrations.promoshopping.enabled)? true : false,

      etcEnabled: false,
      scalapayEnabled: false,
      promoshoppingEnabled: false,
    }
  }
}
</script>

<style scoped lang="scss">


ol>li{
  list-style-type: disc;
  margin-bottom: 15px;
}
.section {
}
</style>